<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-row>
                <b-col md="4">
                    <base-input type="text" :label="$t('general.search_area')" :placeholder="$t('general.search_area')" v-model="filter.title" @input="searchTimeOut">
                    </base-input>
                </b-col>
                <b-col md="4">
                    <base-input :label="$t('general.lang')" name="lang" :nameAs="$t('general.lang')" :rules="'required'">
                        <el-select v-model="filter.lang" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required @change="getData">
                            <el-option v-for="(item, key) in setting.lang" :key="key" :label="item.toUpperCase()" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="4" class="text-right" v-if="userType=='doctor'">
                    <base-button type="success" @click="addNew" class="my--5">
                        <i class="fas fa-plus"></i> {{$t('general.addnew')}}
                    </base-button>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="articles" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')">
                <el-table-column :label="$t('article.photo')" prop="photo" min-width="150">
                    <template v-slot="{row}">
                        <img :src="row.photo" class="img-thumbnail" v-if="row.photo&&row.photo!=''">
                    </template>
                </el-table-column>
                <el-table-column :label="$t('article.title')" prop="title" min-width="250">
                </el-table-column>

                <el-table-column :label="$t('article.publish_status')" prop="status" min-width="160">
                    <template v-slot="{row}">
                        <badge class="badge-dot mr-4" type="">
                            <i :class="`bg-${status[row.status]}`"></i>
                            <span class="status" :class="`text-${status[row.status]}`">{{row.status?$t('article.publish'):$t('article.not_publish')}}</span>
                        </badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.created_date')" prop="createdAt" min-width="170" sortable>
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="180">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="handleEdit(scope.row)">{{$t('general.edit')}}</base-button>
                        <base-button type="danger" size="sm" @click="handleDelete(scope.row.uuid)">{{$t('general.delete')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>
        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modal.status=false" size="lg">
            <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{modal.data.uuid ? $t('article.edit') : $t('article.add')}}</h6>
                <hr class="my-2">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading">
                    <b-row>
                        <b-col md="3" v-if="userType=='admin'">
                            <label class="form-control-label">{{$t('general.status')}}</label>
                            <b-form-checkbox v-model="modal.data.status" class="switchCheck dblock" name="check-button" switch>
                                {{modal.data.status ? $t('general.active') : $t('general.passive')}}
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="3" v-if="profile.branch">
                            <base-input :label="$t('article.branch')" name="branch" :nameAs="$t('article.branch')" :rules="'required'">
                                <el-select v-model="modal.data.branch" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required>
                                    <el-option v-for="(item, key) in profile.branch" :key="key" :label="item | getBranchName" :value="item"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                        <b-col md="2">
                            <base-input :label="$t('article.lang')" name="lang" :nameAs="$t('article.lang')" :rules="'required'">
                                <el-select v-model="modal.data.lang" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required>
                                    <el-option v-for="(item, key) in setting.lang" :key="key" :label="item.toUpperCase()" :value="item"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                        <b-col md="7">
                            <base-input type="text" name="title" :nameAs="$t('article.title')" :label="$t('article.title')" :placeholder="$t('article.title')" v-model="modal.data.title" required>
                            </base-input>
                        </b-col>
                    </b-row>
                    <quill-editor v-model="modal.data.content" :options="editorOption" />
                    <base-button type="default" @click="openMediaModal" size="sm" class="mt-2">{{$t('article.select_media')}}</base-button>
                    <template v-if="modal.data.photo && modal.data.photo!=''">
                        <div class="articleMedia">
                            <span @click="removeMedia">
                                <i class="fas fa-trash-alt"></i>
                            </span>
                            <img :src="modal.data.photo" class="img-thumbnail">
                        </div>
                    </template>
                    <hr class="my-2">
                    <b-alert show variant="warning" v-if="invalid || modal.data.content.trim()==''">
                        <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid || modal.data.content.trim()==''">{{$t('save')}}</base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                </b-form>
            </validation-observer>
        </modal>
        <modal :show.sync="imgModal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="imgModal.status=false" size="lg">
            <h6 slot="header" class="modal-title">{{$t('article.media')}}</h6>
            <hr class="my-2">
            <b-row v-loading="imgModal.loading" v-lazy-container="{ selector: 'img' }">
                <b-col md="3" v-for="(item, index) in imageList" :key="index" class="mb-4">
                    <a href="#" @click.prevent="selectImg(item)">
                        <img :data-src="item" class="img-fluid">
                    </a>
                </b-col>
            </b-row>
            <base-button type="link text-danger" class="ml-auto float-right" @click="imgModal.status = false">{{$t('general.close')}}</base-button>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { ARTICLE_REQUEST, ARTICLE_UPDATE, ARTICLE_ADD, ARTICLE_DELETE, ARTICLE_IMG_LIST } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;

export default {
    data() {
        return {
            timer: null,
            loading: false,
            imgModal: {
                status: false,
                loading: false
            },
            modal: {
                status: false,
                loading: false,
                data: {
                    uuid: false,
                    branch: '',
                    title: '',
                    content: '',
                    lang: 'tr',
                    photo: ''
                }
            },
            status: {
                true: 'success',
                false: 'danger'
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {
                lang: 'tr',
                doctor: ''
            },
            editorOption: {
                modules: {
                    clipboard: {
                        matchVisual: true
                    },
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        ['clean']
                    ]
                },
                placeholder: ''
            }
        };
    },
    computed: {
        ...mapGetters({
            branches: 'getBranchList',
            articles: 'getArticles',
            pages: 'getArticlePages',
            total: 'getArticleTotal',
            setting: 'generalSetting',
            profile: 'getProfile',
            userType: 'getUserType',
            imageList: 'getArticleImages'
        })
    },
    methods: {
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData();
            }, 800);
        },
        openMediaModal() {
            self.imgModal.loading = true;
            self.imgModal.status = true;

            self.$store
                .dispatch(ARTICLE_IMG_LIST)
                .catch(err => {
                    self.imgModal.status = false;
                    handleError(self, err);
                })
                .finally(() => {
                    self.imgModal.loading = false;
                });
        },
        selectImg(item) {
            this.$set(self.modal.data, 'photo', item);
            self.imgModal.status = false;
        },
        removeMedia() {
            this.$set(self.modal.data, 'photo', '');
        },
        changePage(val) {
            self.pagination.page = val;
            self.getData();
        },
        addNew() {
            self.modal = {
                status: true,
                loading: false,
                data: {
                    uuid: false,
                    branch: '',
                    title: '',
                    content: '',
                    lang: 'tr',
                    photo: ''
                }
            };
        },
        handleEdit(row) {
            self.modal = {
                status: true,
                loading: false,
                data: _.cloneDeep(row)
            };
        },
        handleDelete(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(ARTICLE_DELETE, uuid)
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(ARTICLE_REQUEST, { pagination: self.pagination, filter: self.filter, fields: '-_id -updatedAt' })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        onSubmit() {
            if (self.modal.data.uuid) {
                self.onUpdate();
            } else {
                self.onAdd();
            }
        },
        onAdd() {
            let data = _.cloneDeep(self.modal.data);
            self.modal.loading = true;
            self.$store
                .dispatch(ARTICLE_ADD, data)
                .then(() => {
                    self.modal.loading = false;
                    self.modal.status = false;
                    self.getData();
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        onUpdate() {
            let data = _.cloneDeep(self.modal.data);
            data['doctor'] = data.doctor._id;
            data['branch'] = data.branch._id;
            let uuid = data.uuid;
            self.modal.loading = true;
            self.$store
                .dispatch(ARTICLE_UPDATE, { uuid: data.uuid, article: data })
                .then(() => {
                    self.modal.loading = false;
                    self.modal.status = false;
                    self.getData();
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        }
    },
    filters: {
        getBranchName(id) {
            let lang = self.$i18n.locale;
            let branch = _.first(
                _.filter(self.branches, item => {
                    return item._id == id;
                })
            );

            return branch && branch.name[lang] ? branch.name[lang] : self.$i18n.t('error.no_translate');
        }
    },
    created() {
        self = this;
        self.getData();
    }
};
</script>
